export default {
    data() {
        return {
            feature: [{
                title: '考勤数据来源多元化',
                numberPic: require("../../assets/icons/num1.png"),
                text: '考勤采集来源可以是门禁考勤设备、无感抓拍考勤设备、智慧班牌，也可以是教师移动端录入，互不干扰，统一归集，统一呈现，设备支持海康、大华等硬件厂商的主流产品。'
            }, {
                title: '考勤数据及预警信息精准推送',
                numberPic: require("../../assets/icons/num2.png"),
                text: '学生到校刷脸考勤后，考勤数据即刻精准对家长推送。同时如有异常测温数据，系统根据预警预设，第一时间短信推送至班主任及相关责任领导，同时支持抄送信息至卫生老师。'
            }, {
                title: '统计数据精准高效',
                numberPic: require("../../assets/icons/num3.png"),
                text: '班主任、任课教师、学校管理部门通过移动端精准把握各级各类考勤数据，日报周报精准推送，系统后台提供自定义字段的各类考勤及防疫数据导出，学校可以根据管理机制将数据权限做细化分配。如不同部门使用的防疫测温报表、考勤综合报表、缺勤原因报表可根据需要勾选所需字段，一键导出。'
            }, {
                title: '数据随需呈现，精彩纷呈',
                numberPic: require("../../assets/icons/num4.png"),
                text: '考勤数据大屏、实时通行大屏、全校放学信息大屏、实时通行大屏、班级智慧班牌同步呈现数据。另外，数据有标准的Api接口，第三方在授权许可的前提下可以对接考勤数据。'
            }, {
                title: '具备安全优势的数据传输机制',
                numberPic: require("../../assets/icons/num4.png"),
                text: '我司的校智联边缘计算融合器，可将主流门禁考勤的内网考勤数据传输至互联网，而不需要通过云眸、云睿等物联网平台做中转，保证了学生核心信息数据，人脸数据等内网传输，有效了保证了数据的安全性。'
            }],
        }
    },
    created() {

    },
}