<template>
  <div class="sshx">
    <div class="container">
      <div class="banner">
        <div class="auto-container">
          <h1>智慧考勤系统</h1>
          <p><a href="/">首页</a>
            <Icon
              class="arrow"
              type="ios-arrow-forward"
            />我们的优势
            <Icon
              class="arrow"
              type="ios-arrow-forward"
            />智慧考勤系统
          </p>
        </div>
      </div>
      <div class="auto-container">
        <section class="rules-section">
          <div class="auto-container">
            <Row>
              <Col :xl="12" :lg="12">
              <div class="inner-column" style="padding: 0">
                <div class="image">
                  <a href="#"><img
                      src="../../assets/resource/sshx.png"
                      alt=""
                    /></a>
                </div>
              </div>
              </Col>

              <Col :xl="12" :lg="12">
              <div class="inner-column">
                <div class="sec-title">
                  <div class="title-icon">
                    <span class="icon"><img
                        src="../../assets/icons/separater.png"
                        alt=""
                      /></span>
                  </div>
                  <h2>智慧考勤系统</h2>
                </div>
                <div class="text">
                  <p>校园智慧考勤精准放学系统包含对进出人员、师生实现有效身份识别，并记录考勤数据及出入即时图像，系统可以统一远程采集人脸数据、分发人脸数据，
                    授权各权限组设备，通过识别算法识别、系统自动推送考勤信息至家长及响应权限管理人员，同时包含接送系统及请假单独自动授权门禁等功能，
                    系统中支持在不同场所展示不同上放学大数据看板。</p>
                </div>
              </div>
              </Col>
            </Row>
          </div>
        </section>

        <Row style="padding-bottom:80px">
          <Col
            :xl="8" :lg="8" :md="12" :sm="24"
            v-for="(item,index) in feature"
            :key="index"
          >
          <div class="feature-block-two">
            <div class="inner-box ">
              <div class="icon-box">
                <img :src="item.numberPic" alt="" />
              </div>
              <h3>{{item.title}}</h3>
              <div class="text">{{item.text}}</div>
            </div>
          </div>
          </Col>
        </Row>

        <div class="sec-title">
          <div class="title-icon">
            <span class="icon"><img src="../../assets/icons/separater.png" alt="" /></span>
          </div>
          <h2>图集</h2>
        </div>
        <Carousel v-model="value" loop autoplay dots="none" arrow="always" autoplay-speed="5000">
          <CarouselItem>
            <div class="picBox">
              <img class="pic" src="../../assets/pic/zhkq1.png" alt="" srcset="">
            </div>
          </CarouselItem>
          <CarouselItem>
            <div class="picBox">
              <img class="pic" src="../../assets/pic/zhkq2.png" alt="" srcset="">
            </div>
          </CarouselItem>
          <CarouselItem>
            <div class="picBox">
              <img class="pic" src="../../assets/pic/zhkq3.png" alt="" srcset="">
            </div>
          </CarouselItem>
        </Carousel>
      </div>
    </div>
  </div>
</template>
<script>
import zhkq from "./zhkq.js";
export default zhkq;
</script>

<style lang="less" scoped>
@import "./zhkq.less";
</style>